








































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import SelectMenu from "@/components/SelectMenu.vue";
import UserList from "@/components/UserList.vue";
import { hostGame } from "@/rest/Game";
import { messaging } from "./../rio-proto.js";
import GameCard from "@/components/GameCard.vue";
import GameStatus from "@/components/GameStatus.vue";
import HintBannerBottom from "@/components/HintBannerBottom.vue";
import Notification from "@/components/Notification.vue";
import JoinCode from "@/components/JoinCode.vue";
import { ConnectionErrorNoSessionCookie } from "@/store/models/GameStateModel";

const GameStateModule = namespace("gameStateModule");

@Component({
  components: {
    SelectMenu,
    UserList,
    GameCard,
    GameStatus,
    HintBannerBottom,
    Notification,
    JoinCode,
  },
})
export default class Host extends Vue {
  @Prop() private msg!: string;
  @GameStateModule.Getter
  private connectionError!: string;
  @GameStateModule.Getter
  private playerStatus!: messaging.PlayerUpdate;
  @GameStateModule.Getter
  private gameTitle!: string;
  @GameStateModule.Getter
  private gameStatus!: messaging.GameStatus;
  @GameStateModule.Getter
  private gameTopic!: string;
  @GameStateModule.Getter
  private isUnanimousVote!: boolean;
  @GameStateModule.Getter
  private highlightedPlayers!: string[];
  private selectedCardDeck = "default cards";
  private playerName = "HOST";
  private formGameTopic = "";
  private formTopicLink = "";
  private gameID = this.$route.params.gameID;
  private showMobileMenu = false;
  private showJoinCode = false;
  private notification = { title: "", description: "" };

  mounted() {
    this.$websocketConnect(this.gameID, true, "HOST");
    this.formGameTopic = this.gameTopic;
  }
  get refineButtonText(): string {
    switch (this.gameStatus) {
      case messaging.GameStatus.NEW:
        return "Refine";

      default:
        if (this.formGameTopic != "" && this.formGameTopic != this.gameTopic) {
          return "Switch";
        }
        return "Re-Estimate";
    }
  }

  startSession(): void {
    this.$websocketSend(
      messaging.Container.create({
        hostUpdate: {
          gameStatus: messaging.GameStatus.RUNNING,
          gameTopic: this.formGameTopic,
          gameTopicLink: this.formTopicLink,
        },
      })
    );
  }

  removePlayer(player: string): void {
    console.log("remove");
    this.$websocketSend(
      messaging.Container.create({
        kickUser: {
          UserID: player,
        },
      })
    );
  }

  onCardDeckSelect(value: string): void {
    this.selectedCardDeck = value;
  }

  copyJoinURL(): void {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = this.generateJoinURL();
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    this.notification = {
      title: "Link copied to clipboard!",
      description:
        "Forward this link to your team members, so they can join the session.",
    };
  }

  generateJoinURL(): string {
    return location.origin + "/#/j/" + this.gameID;
  }

  get isRunningRound(): boolean {
    return this.gameStatus === messaging.GameStatus.RUNNING;
  }

  get isFinishedRound(): boolean {
    return this.gameStatus === messaging.GameStatus.FINISHED;
  }

  get unanimousVotedCard(): messaging.CardDeckCards {
    return Number(Object.values(this.playerStatus)[0]);
  }

  @Watch("connectionError")
  onConnetionChanged(newStatus: string, oldStatus: string) {
    if (newStatus === ConnectionErrorNoSessionCookie) {
      this.$router.push("/j/" + this.gameID);
    }
  }
}
