































































import { Component, Prop, Vue } from "vue-property-decorator";
import QrcodeVue from "qrcode.vue";

@Component({
  components: {
    QrcodeVue,
  },
})
export default class JoinCode extends Vue {
  @Prop()
  private show!: boolean;

  @Prop()
  private value!: string;

  mounted() {}

  onDismiss(): void {
    this.$emit("dismissed", true);
  }
}
